<template>
  <div class="pageBox">
    <div class="contentBox">
      <!--   首页切换图片   -->
      <div class="swiperBg" :style="'background-image: url(' + activeBgImg + ')'">
        <div :class="{'infoList':true,'isShow':isColor}">
          <div class="top">{{dataTime}}</div>
          <div class="list">
            <div class="item" v-for="(item,index) in tType=='EN'?iconEnlist:iconlist" :key="index" @click="goCar(item)">
              <div class="img">
                <img :src="item.img" width="100%" height="100%" alt="">
              </div>
              <div class="title">{{item.title}}</div>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
        </div>
        <div :class="{'swiperList':true,'isShowSwiper':isColor}">
          <div class="prev" @click="iconleft"></div>
          <div class="num">
            <span class="start">{{start<10?'0'+start:start}}</span>
            <span class="end">/{{bgImgList.length==1?bgImgList.length:'0'+bgImgList.length}}</span>
          </div>
          <div class="next" @click="iconright"></div>
        </div>
      </div>
      <!--   概括   -->
      <div class="generalizeBox">
        <div class="con">

          <div class="title" v-if="tType == 'CH'">{{ GKtitle }}</div>
          <div class="title enText" v-if="tType == 'EN'">Welcome to<br/>Wuxi Jiangnan Ancient Canal Tourist Resort!</div>
          <div class="text" v-html="duJia">
<!--            <p>江南古运河在无锡清名桥处与世界上最古老的运河伯渎港交汇，-->
<!--              是1794公里京杭大运河保存风貌最完整、保存历史遗存最多、-->
<!--              最具有江南水乡风情，并且唯一穿城而过的一段古运河。至今仍延续着通航、-->
<!--              蓄水、防洪、排涝、生态、景观、游憩等功能。-->
<!--            </p>-->
<!--            <p>江南古运河旅游度假区沿线有国保单位6处，-->
<!--              省保单位24处，环城古运河“绕城而过，独此一环”，还有清名桥、惠山两大历史文化街区以及黄埠墩、-->
<!--              米市、丝市、名人故居等旅游和历史文化资源，承载了运河文化深厚底蕴。特别是从南长桥至清名桥1.6公里长的古运河段，-->
<!--              寺、塔、河、街、桥、窑、宅、坊众多空间元素有机组合，成为一幅鲜活的“清明上河图”，被誉为“江南水弄堂、运河绝版地”。-->
<!--              度假区目前拥有2个世界文化遗产点段、6个国家4A级景区和1个中国历史文化名街。-->
<!--            </p>-->

          </div>
          <div class="btn" v-if="tType == 'CH'" @click="$router.push('/vacation/vacation')">
            了解更多
          </div>
          <div class="btn" v-if="tType == 'EN'" @click="$router.push('/vacation/vacation')">
            more
          </div>
        </div>
      </div>
      <!--   动态资讯   -->
      <div class="Dynamic">
        <div class="title">
          <div class="text" v-if="tType == 'CH'">
            <span>动态</span>资讯
          </div>
          <div class="text" v-if="tType == 'EN'">
            <span>Dynamic</span>&nbsp;information
          </div>
          <div class="lookAll">
             <div @click="$router.push('/information/announcement')" v-if="tType == 'CH'">查看更多</div>
             <div @click="$router.push('/information/announcement')" v-if="tType == 'EN'">view all</div>

          </div>
        </div>
        <div class="DyList">
          <span class="el-icon-arrow-left hoverH DynamicLeft leftBtn"></span>
          <span class="el-icon-arrow-right hoverH DynamicRight rightBtn" style="margin-right: 0;"></span>
          <swiper :options="swiperOption2">
            <swiper-slide v-for="(item,index) in dynamicinfomationList" class="item-swiper">
            <div class="items" @click="goInfo(item,1)">

              <div class="img scale"><img :src="item.dynamicinfomationCover" alt=""></div>
              <div class="con">
                <div class="tit">
                  {{ item.webTitle }}
                </div>
                <div class="time">
                  {{ tType == 'EN' ? item.dynamicinfomationCreateTime : item.dynamicinfomationCreateTime }}
                </div>
                <div class="conText">
                  {{ item.webDesc }}
                </div>
              </div>

            </div>
            </swiper-slide>
          </swiper>

        </div>
      </div>
      <!--    景点  -->
      <div class="scenic">
        <div class="title">
          <div class="text" v-if="tType == 'CH'">
            <span>景点</span>游览
          </div>
          <div class="text" v-if="tType == 'EN'">
            <span>Attractions</span>&nbsp;tour
          </div>
          <div class="lookAll">
            <div @click="$router.push('/playturn/haveScenery')" v-if="tType == 'CH'">查看更多</div>
            <div @click="$router.push('/playturn/haveScenery')" v-if="tType == 'EN'">view all</div>


          </div>
        </div>
        <div class="con">
          <div class="scList">
            <span class="el-icon-arrow-left hoverH scenicLeft leftBtn"></span>
            <span class="el-icon-arrow-right hoverH scenicRight rightBtn" style="margin-right: 0;"></span>
            <swiper :options="swiperOption3">
              <swiper-slide v-for="(item,index) in jingdianList" class="item-swiper">
                <div class="item " @click="goInfo(item,2)">
                  <div class="bg"></div>
                  <div class="img scale">
                    <img :src="item.sightseeingCover" alt="">
                  </div>
<!--                  <img src="../../assets/img/ceng.png" alt="">-->
                  <div class="text">{{ item.webTitle }}</div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <!--   特色消费   -->
      <div class="feature">
        <div class="title">
          <div class="text" v-if="tType == 'CH'">
            <span>特色</span>消费
          </div>
          <div class="text" v-if="tType == 'EN'">
            <span>Featured</span>&nbsp;consumption
          </div>
          <div class="lookAll">
            <div @click="$router.push('/featureConsumption')" v-if="tType == 'CH'">查看更多</div>
            <div @click="$router.push('/featureConsumption')" v-if="tType == 'EN'">view all</div>
<!--            <span class="el-icon-arrow-left hoverH featureLeft"></span>-->
<!--            <span class="el-icon-arrow-right hoverH featureRight" style="margin-right: 0;"></span>-->
          </div>
        </div>
        <div class="con">
          <span class="el-icon-arrow-left hoverH featureLeft leftBtn"></span>
          <span class="el-icon-arrow-right hoverH featureRight rightBtn" style="margin-right: 0;"></span>
          <swiper :options="swiperOption4">
            <swiper-slide v-for="(item,index) in youliList" class="item-swiper">
              <div class="img scale" @click="goPolite(item)">
<!--                <img :src="item.productCover" alt="">-->
                <el-image
                    class="imgs"
                    :src="item.productCover"
                    fit="cover"></el-image>
                <img src="../../assets/img/mask.png" alt="" class="mask">
              </div>
<!--              <div class="imgs"></div>-->
              <div class="conText" @click="goPolite(item)">
                <div class="text1">{{ item.productTitle }}</div>
                <div class="tabs">
<!--                  <div class="tab"><div>特色饮食</div></div>-->
                </div>
                <div class="text2" style="margin-top: 20px">{{item.productDesc}}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navbar from '@/components/navbar/navbar'
import { dynamicinfomationList,productList,bannerList,weatherList,situationList } from '@/api/index'
import { parseTime, ENdateTranslation } from '@/utils/index'
//导入swiper
import { swiper, swiperSlide ,directive } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {getproduct} from "@/api/table";
export default {
  name: 'Login',
  directives: {
    swiper: directive
  },
  components:{

  },
  data() {
    this.scrollDom = null;
    return {
      tType:'CH',
      swiperOption2: {
        navigation: {
          nextEl: '.DynamicRight',
          prevEl: '.DynamicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      swiperOption3: {
        navigation: {
          nextEl: '.scenicRight',
          prevEl: '.scenicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      swiperOption4: {
        navigation: {
          nextEl: '.featureRight',
          prevEl: '.featureLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      isColor: false,
      iconlist:[
        {id:0,img:require('../../assets/img/TQ.png'),title:"阴天",text:"天气"},
        {id:1,img:require('../../assets/img/WD2.png'),title:"18°C",text:"温度"},
        // {id:2,img:require('../../assets/img/KL.png'),title:"未对接",text:'实时客流'},
        {id:2,img:require('../../assets/img/KQ1.png'),title:"优",text:'空气质量'},
        // {id:3,img:require('../../assets/img/ZDCZL.png'),title:"5000",text:"最大承载量"},
        {id:4,img:require('../../assets/img/CW.png'),title:"全部停车场",text:"查看"},
      ],
      iconEnlist:[
        {id:0,img:require('../../assets/img/TQ.png'),title:"阴天",text:"weather"},
        {id:1,img:require('../../assets/img/WD2.png'),title:"18°C",text:"temperature"},
        // {id:2,img:require('../../assets/img/KL.png'),title:"未对接",text:'passenger flow'},
        {id:2,img:require('../../assets/img/KQ1.png'),title:"excellent",text:'air quality'},
        // {id:3,img:require('../../assets/img/ZDCZL.png'),title:"5000",text:"最大承载量"},
        {id:4,img:require('../../assets/img/CW.png'),title:"all parking lot",text:"look over"},
      ],
      start:1,
      end:3,
      activeBgImg:'../../assets/img/jiudian.png',
      bgImgList:[
        // 'https://xiaochengxu-1309102353.cos.ap-nanjing.myqcloud.com/huyunhe/RTnBLJ0Ht9bL6b27fd7db62f24ec14967bd2e2456543.png',
      ],
      dynamicinfomationList:[
        // {
        //   img:'https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/1653655737000.png',
        //   title:'遏制福寿螺蔓延 保护古运…',
        //   time:'2022-01-24',
        //   info:'近日，在无锡市多处湿地、公园发现了福寿螺的身影。福寿螺作为外来生物，其适应环境的生…'
        // }
      ],//动态资讯列表
      jingdianList:[
        // {
        //   img:'https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/1653655839000.png',
        //   title:'清名桥历史文化街区',
        // }
      ],
      youliList:[],
      GKtitle:'',
      duJia:'',
      weatherData:{},
      dataTime:'',
      customerNum:'',
      timer: null
    }
  },
  computed: {
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted(){

    this.$nextTick(()=>{
      // console.log(this.$refs.maxContextBox)
      // console.log(document.getElementsByClassName('maxContextBox')[0])
      this.handleScroll()
      // this.scrollDom = this.$refs.maxContextBox;
      // this.scrollDom = document.getElementsByClassName('maxContextBox')[0];
      this.initEvent(this.scrollDom)
    })
    this.getdynamicinfomationList()
    this.getProductList()
    this.getBanner()
    this.getWeatherList()
    this.getSituationList()
    this.getTime()

    this.timer = setInterval(this.myTimer, 5000)
  },
  methods: {
    myTimer(){
      this.iconright('DS')
    },
    getTime() {
      setInterval(()=>{
        let date=new Date()
        let hour =date.getHours()<10?'0'+date.getHours():date.getHours()
        let minutes =date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()
        let seconds =date.getSeconds()<10?'0'+date.getSeconds():date.getSeconds()

        if(this.tType == 'EN'){
          // this.dataTime=date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'- '+hour+':'+minutes+':'+seconds
          this.dataTime=ENdateTranslation(date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'- '+hour+':'+minutes+':'+seconds)
        }else{
          this.dataTime=date.getFullYear()+'年'+(date.getMonth()+1)+'月'+date.getDate()+'日 '+hour+':'+minutes+':'+seconds
        }
      },1000)
    },
    async getWeatherList(){
      let date=new Date()
      let time=parseTime(date)
      console.log(time.split(' '))
      await weatherList({}).then(res=>{
        console.log(res)
        if(res.code==200){
          res.data.forecast.forEach((item)=>{
            if(item.ymd==time.split(' ')[0]){
              // this.weatherData=item
              this.TQSelect(item.type)
              // this.iconlist[0].title=item.type

              this.iconlist[1].title=item.low.split(' ')[1]+'~'+item.high.split(' ')[1]
              this.iconEnlist[1].title=item.low.split(' ')[1]+'~'+item.high.split(' ')[1]
              this.WDSelect(item.low.split(' ')[1].substr(0,2),item.high.split(' ')[1].substr(0,2))
            }
          })
          if(res.data.quality){
            this.KQSelect(res.data.quality)
            // this.iconlist[2].title=res.data.quality
            // this.iconEnlist[2].title=res.data.quality
          }

        }
      })
    },
    // 天气筛选
    TQSelect(text){
      if(this.tType == 'EN'){
        switch (text){
          case '晴':
            text = 'clear'
            break;
          case '阴'||'多云':
            text = 'cloudy'
            break;
          case '小雨':
            text = 'sprinkle'
            break;
          case '雷阵雨':
            text = 'thundershower'
            break;
          case '雨夹雪':
            text = 'sleet'
            break;
          default:
            text = 'cloudy'
        }
      }

      switch (text){
        case '晴'||'clear':
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ1.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ1.png')
          break;
        case '多云'||'cloudy'||'阴':
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ.png')
          break;
        // case '阴'||'阴':
        //   this.iconlist[0].title = text
        //   this.iconlist[0].img = require('../../assets/img/TQ2.png')
        //   this.iconEnlist[0].title = text
        //   this.iconEnlist[0].img = require('../../assets/img/TQ2.png')
        //   break;
        case '小雨'||'sprinkle':
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ3.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ3.png')
          break;
        case '雷阵雨'||'thundershower':
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ4.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ4.png')
          break;
        case '雨夹雪'||'sleet':
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ5.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ5.png')
          break;
        default:
          this.iconlist[0].title = text
          this.iconlist[0].img = require('../../assets/img/TQ.png')
          this.iconEnlist[0].title = text
          this.iconEnlist[0].img = require('../../assets/img/TQ.png')
      }
    },
    // 温度筛选
    WDSelect(min,max){
      // console.log(min,max)
      if(max >= 30){
        this.iconlist[1].img = require('../../assets/img/WD1.png')
        this.iconEnlist[1].img = require('../../assets/img/WD1.png')
      }
      if(max < 30 && max > 10){
        this.iconlist[1].img = require('../../assets/img/WD2.png')
        this.iconEnlist[1].img = require('../../assets/img/WD2.png')
      }
      if(max <= 10 && max > 0 ){
        this.iconlist[1].img = require('../../assets/img/WD3.png')
        this.iconEnlist[1].img = require('../../assets/img/WD3.png')
      }
      if(max < 10 ){
        this.iconlist[1].img = require('../../assets/img/WD4.png')
        this.iconEnlist[1].img = require('../../assets/img/WD4.png')
      }
    },
    // 空气筛选
    KQSelect(text){
      if(this.tType == 'EN'){
        switch (text){
          case '优':
            text = 'excellent'
            break;
          case '良':
            text = 'good'
            break;
          case '轻度污染':
            text = 'mild contamination'
            break;
          case '中度污染':
            text = 'middle level pollution'
            break;
          case '重度污染':
            text = 'serious contamination'
            break;
          case '严重污染':
            text = 'severe contamination'
            break;
          default:
            text = 'excellent'
        }
      }
      switch (text){
        case '优'||'excellent':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ1.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ1.png')
          break;
        case '良'||'good':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ2.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ2.png')
          break;
        case '轻度污染'||'mild contamination':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ3.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ3.png')
          break;
        case '中度污染'||'middle level pollution':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ4.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ4.png')
          break;
        case '重度污染'||'serious contamination':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ5.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ5.png')
          break;
        case '严重污染'||'severe contamination':
          this.iconlist[2].title = text
          this.iconlist[2].img = require('../../assets/img/KQ6.png')
          this.iconEnlist[2].title = text
          this.iconEnlist[2].img = require('../../assets/img/KQ6.png')
          break;
        default:

      }
    },
    async getSituationList(){
      await situationList({}).then(res=>{
        console.log(res)
        if(res.code==200){
          // this.customerNum=res.data
          /*this.iconlist[2].title=res.data
          this.iconEnlist[2].title=res.data*/
        }
      })
    },
    //动态资讯
    async getdynamicinfomationList(){
      await dynamicinfomationList({}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.dynamicinfomationList=res.rows
        }
      })
    },
    async getProductList(){
      await productList({
        sightseeingType:'26'
      }).then(res=>{
        if(res.code==200){
          this.jingdianList=res.rows
        }
      })
      await getproduct({
        productType: '1',   // 必填 类型 1休闲消费 2非遗文创
      }).then(res=>{
        // console.log(res)
        if (res.code==200) {
          this.youliList=res.rows
          // this.datalist=res.rows
          // this.datalist.forEach((item,index)=>{
          //   if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
          // })
          // this.totalCount=res.total
        }
      })
      // await productList({
      //   sightseeingType:'29'
      // }).then(res=>{
      //   if(res.code==200){
      //   }
      // })
    },
    async getBanner(){
      await bannerList({
        bannerType:'4'
      }).then(res=>{
        console.log(res)
        if(res.code==200){
          this.bgImgList=res.rows[0].image.split(',')
          this.activeBgImg = this.bgImgList[0]
          console.log(this.bgImgList)
        }
      })
      await bannerList({
        bannerType:'6'
      }).then(res=>{
        console.log(res)
        if(res.code==200){
          // this.bgImgList=(res.rows[0].image.split(','))
          this.GKtitle = res.rows[0].bannerTitle
          this.duJia=res.rows[0].content
          console.log(this.duJia)
        }
      })
    },
    refreshPage() {
      // this.orderQueryAll()
      setTimeout(() => {
        // this.start++
        if(this.start++ == this.bgImgList.length){
          this.start = 1
        }
        this.activeBgImg = this.bgImgList[this.start-1]
        this.refreshPage()
      }, 5000);
    },
    iconleft(){
      if(this.start == 1){
        this.start = 4
        this.start<this.bgImgList.length && this.start--
      }else{
        this.start<=this.bgImgList.length && this.start--
      }
      // if(this.start===1){
      //   this.start=1
      //   return
      // }
      console.log(this.start)
      this.activeBgImg = this.bgImgList[this.start-1]
      console.log(this.activeBgImg,this.start)
    },
    iconright(str){
      if(this.start >= 4){
        this.start = 0
      }
      if(str == 'DS'){
        this.start<this.bgImgList.length && this.start++
        this.activeBgImg = this.bgImgList[this.start-1]
      }else{
        this.start<this.bgImgList.length && this.start++
        this.activeBgImg = this.bgImgList[this.start-1]
      }

      console.log(this.activeBgImg,this.start)
    },
    handleScroll() {
      // this.scrollDom = this.$refs.contentBox;
      this.scrollDom = document.getElementsByClassName('app-main')[0];
    },
    initEvent(dom) {
      // console.log(dom)
      dom.addEventListener('scroll', this.scrollHander);
    },
    scrollHander() {
      // console.log(this.scrollDom);
      const top = this.scrollDom.scrollTop;
      if(top<10){
        this.isColor = false
      }else{
        this.isColor = true
      }
      // console.log(top);
    },
    goCar(item){
      // console.log(item)
      if(item.id==4){
        this.$router.push('/car')
      }
    },
    goInfo(item,num){
      console.log(item,num)
      if(num==1){
        // /information/announcement
        this.$router.push({path:'/information/informationInfo',query:{id:item.dynamicinfomationId}})
        // this.$router.push({path:'/playturn/haveSceneryInfo',query:{id:item.sightseeingId}})
      }else{
        // 运河有景详情页面
        this.$router.push({path:'/playturn/haveSceneryInfo',query:{id:item.sightseeingId,type:'26'}})
      }
    },
    goPolite(item){
      // console.log(item)
      this.$router.push({path:'/featureConsumptionInfo',query:{id:item.productId,type:'29'}})
    }
  },
  destroyed() {
    //清除定时器
    clearInterval(this.timer);
    // console.log("beforeDestroy");
  },
}
</script>

<style scoped lang="less">
.hoverH{
  //background:red;
  //&:hover {
  //  color: #043843;
  //}
}
.pageBox{
  width: 100%;
  height: calc(100% - 110px);
  position:relative;
  //background-image: url("../../../public/img/bg2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.contentBox{
  width: 100%;
  .swiperBg{
    width: 100%;
    //height: 960px;
    height: calc(100vh - 120px);
    position: relative;
    background-repeat: no-repeat;
    //background-size: 100% 100%;
    background-size: cover;
    transition:all 500ms ease;
    background-attachment: fixed;
    .infoList{
      background: rgba(255,255,255,0.9);
      border-radius: 0px 40px 40px 0px;
      padding: 24px 50px;
      position: absolute;
      left: 0;
      bottom: 60px;
      width: 550px;
      //transition:all 1000ms ease;
      .top{
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 30px;
        //line-height: 30px;
      }
      .list{
        display: flex;
        justify-content: space-between;
        .item{
          flex: none;
          text-align: center;

          .img{
            width: 53px;
            height: 53px;
            margin-left:50% ;
            transform: translateX(-50%);
          }
          .title{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            margin: 8px 0 10px 0;
          }
          .text{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }

    }
    .isShow{
      //display: none;
      left: -600px;
      transition:all 1000ms ease;
      //transition: display 0.5s;
    }
    .swiperList{
      position: absolute;
      right: 0px;
      bottom: 0px;
      background: #000000;
      opacity: 0.4;
      backdrop-filter: blur(10px);
      padding: 20px 0;
      display: flex;
      align-items: center;
      transition:all 1000ms ease;
      .prev{
        width: 46px;
        height: 46px;
        margin: 0 40px;
        background-image: url("../../../public/img/JT.png");
        background-repeat: no-repeat;
        background-size: 100%;
        cursor:pointer;
        &:hover{
          background-image: url("../../../public/img/JTyou.png");
          transform: rotate(180deg);
        }
        img{
          width: 100%;
          height: 100%;
        }
        .imgColor{
          transform: rotate(180deg);
        }
      }
      .next{
        width: 46px;
        height: 46px;
        margin: 0 40px;
        background-image: url("../../../public/img/JT.png");
        transform: rotate(180deg);
        background-repeat: no-repeat;
        background-size: 100%;
        cursor:pointer;
        &:hover{
          background-image: url("../../../public/img/JTyou.png");
          transform: rotate(360deg);
        }
        img{
          width: 100%;
          height: 100%;
        }
        .imgColor{
          transform: rotate(180deg);
        }
      }
      .num{
        .start{
          font-size: 60px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #fff;
        }
        .end{
          font-size: 30px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          //color: rgba(153, 153, 153, 1);
          color: rgba(191, 191, 191, 1);
        }
      }
    }
    .isShowSwiper{
      //display: none;
      opacity: 0;
      transition: opacity 2s;
      //transition:all 1000ms ease;
    }
  }
  .generalizeBox{
    overflow: hidden;
    height: 860px;
    background: url("../../assets/img/new/GK3.png") left 100%/101% no-repeat, linear-gradient(270deg, rgba(51, 125, 141, 0) 0%, #007B8A 100%);
    box-shadow: 100px 100px 70px 30px rgb(249, 249, 249);
    .con{
      width: 1200px;

      margin: auto ;
      .title{
        font-size: 54px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #FFFFFF;
        margin: 140px 0 40px 0;
      }
      .enText{
        font-size: 44px;
      }
      .text{
        width: 620px;
        font-size: 18px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
        p{
          margin: none;
          text-indent:2em;
        }
      }
      .btn{
        width: 160px;
        border: 1px solid #FFFFFF;
        font-size: 18px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: bold;
        color: #FFFFFF;
        padding: 12px 0;
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
        &:hover{
          color: #337D8D;
          background: #ffffff;
        }
      }
    }
  }
  .Dynamic{
    background: #F5F5F5;
    padding: 125px 0 80px 0;
    .title{
      width: 1200px;
      margin: 0px auto 35px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .text{
        font-size: 54px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #000;
        span{
          color: #337D8D;
        }
      }
      .lookAll{
        margin-bottom: -5px;
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        span{
          padding: 5px;
          border-radius: 50%;
          margin: 0 20px;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          &:hover{
            background: #00000022;
            //color: #337D8D;
            color: #043843;
          }
        }
      }
    }
    .DyList:hover{

      .leftBtn,.rightBtn{
        display: block;
        transition:all 1000ms ease;
      }
    }
    .DyList{
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      position: relative;

      .leftBtn,.rightBtn{

        display: none;
        position: absolute;
        top: 50%;
        left: 25px;
        width: 50px;
        height: 80px;
        background: rgba(0,0,0,0.5);
        z-index: 5;
        text-align: center;
        line-height: 80px;
        border-radius:0 10px 10px 0;
        transform: translate(-50%, -50%);
        font-size: 25px;
        color: #fff;
        transition:all 1000ms ease;
      }
      .rightBtn{
        border-radius:10px 0 0 10px;
        left: auto;
        right: 25px;
        transform: translate(50%, -50%);
      }
      .item-swiper{
        max-width: 300px;
        background: #FFFFFF;
        border-radius: 20px;
        margin-right: 30px;
      }
      .items:hover{
        .con{
          .tit{
            color: #337D8D;
          }
        }
      }
      .items{
        flex: none;
        max-width: 300px;
        background: #FFFFFF;
        border-radius: 20px;
        //margin-right: 30px;
        overflow: hidden;

        .img{
          width: 100%;
          height: 200px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;

          }
        }
        .con{
          padding:24px 24px 36px 24px;
          .tit{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
            line-height: 28px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; //行数
            overflow: hidden;
          }
          .time{
            font-size: 14px;
            font-family: STHeitiSC-Medium, STHeitiSC;
            font-weight: 500;
            color: #999999;
            line-height: 14px;
            margin: 16px 0;
          }
          .conText{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; //行数
            overflow: hidden;
          }
        }

      }
    }
  }
  .scenic{
    width: 100%;
    height: 430px;
    background: #337D8D;
    padding: 100px 0 0;
    .title{
      width: 1200px;
      margin: 0px auto 35px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .text{
        font-size: 54px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #ffffff33;
        span{
          color: #ffffff;
        }
      }
      .lookAll{
        margin-bottom: -5px;
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        span{
          padding: 5px;
          border-radius: 50%;
          margin: 0 20px;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          &:hover{
            background: #ffffff22;
            color: #043843;
          }
        }
      }
    }
    .con{
      width: 1200px;
      margin: auto;
      .scList:hover{
        .leftBtn,.rightBtn{
          display: block;
        }
      }
      .scList{
        display: flex;
        justify-content: space-between;
        position: relative;
        .leftBtn,.rightBtn{

          display: none;
          position: absolute;
          top: 50%;
          left: 25px;
          width: 50px;
          height: 80px;
          background: rgba(0,0,0,0.8);
          z-index: 5;
          text-align: center;
          line-height: 80px;
          border-radius:0 10px 10px 0;
          transform: translate(-50%, -50%);
          font-size: 25px;
          color: #fff;
          transition:all 1000ms ease;
        }
        .rightBtn{
          border-radius:10px 0 0 10px;
          left: auto;
          right: 25px;
          transform: translate(50%, -50%);
        }
        .item-swiper{
          width: 380px;
          height: 465px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(13, 13, 13, 0.7) 100%);
          border-radius: 20px;
          margin-right: 30px;
        }
        .item{
          flex: none;
          width: 380px;
          height: 465px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(13, 13, 13, 0.7) 100%);
          border-radius: 20px;
          overflow: hidden;
          position: relative;
          .bg{
            width: 100%;
            height: 150px;
            background-image: url("../../assets/img/ceng.png");
            background-size: cover;
            position: absolute;
            bottom: 0;
          }
          .img,img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .text{
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 33px;
            font-size: 28px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }
    }
  }
  .feature{
    padding: 395px 0 250px;
    .title{
      width: 1200px;
      margin: 0px auto 35px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .text{
        font-size: 54px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #000;
        span{
          color: #337D8D;
        }
      }
      .lookAll{
        margin-bottom: -5px;
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        span{
          padding: 5px;
          border-radius: 50%;
          margin: 0 20px;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          &:hover{
            background: #ffffff22;
            color: #043843;
          }
        }
      }
    }
    .item-swiper{
      width: 1200px;
      background: #007B8A;
      border-radius: 20px;
      display: flex;
      flex-wrap: nowrap;
    }
    .con:hover{
      .leftBtn,.rightBtn{
        display: block;
      }

    }
    .con{
      width: 1200px;
      margin: auto;
      background: #007B8A;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      .leftBtn,.rightBtn{

        display: none;
        position: absolute;
        top: 50%;
        left: 25px;
        width: 50px;
        height: 80px;
        background: rgba(0,0,0,0.5);
        z-index: 5;
        text-align: center;
        line-height: 80px;
        border-radius:0 10px 10px 0;
        transform: translate(-50%, -50%);
        font-size: 25px;
        color: #fff;
        transition:all 1000ms ease;
      }
      .rightBtn{
        border-radius:10px 0 0 10px;
        left: auto;
        right: 25px;
        transform: translate(50%, -50%);
      }
      .img{
        width: 722px;
        height: 400px;
        position: relative;
        .imgs{
          width: 100%;
          height: 100%;
        }
        .mask{
          width: 100%;
          height: 400px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      //.imgs{
      //  width: 722px;
      //  position:absolute;
      //  left:0;
      //  top: 0;
      //  img{
      //    width: 100%;
      //    height: 100%;
      //  }
      //}
      .conText{
        max-width: calc(100% - 722px);
        padding: 120px 40px 0 55px;
        .text1{
          font-size: 34px;
          font-family: STSongti-SC-Bold, STSongti-SC;
          font-weight: bold;
          color: #FFFFFF;
        }
        .tabs{
          display: flex;
          .tab{
            margin: 16px 0 20px 0;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 17px;
            opacity: 0.7;
            padding: 6px 15px;
            div{
              font-size: 16px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
        .text2{
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #FFFFFF;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //行数
          overflow: hidden;
          line-height: 21px;

        }
      }
    }
  }
}

</style>
